<template>
    <div  > 
        <div style=" height:275px;overflow-y:auto;">
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
                <span style="font-size:14px"> 一、推广横图 ( 提示：保存后将会改变前端界面，图片大小：1230x200 )
                    <el-link :underline="false" @click="advertEdit"><i class="iconfont icon-bianji" style="font-size:15px;color:white;padding-left:25px;">【更新横条图片】</i></el-link>
                </span>
                <div style="margin-top:10px;">
                    <el-image   :src='advertPath' fit="scale-down" style="width:1230px;height:200px;" ></el-image>
                </div> 
            </div>
        </div>
        
        <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
            <span style="font-size:14px"> 二、推荐视频与画册 ( 提示：保存后将会改变前端界面，图片:295x275；PDF目录 )
                <el-link :underline="false" @click="addVideo"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增记录】</i></el-link>
                <el-link :underline="false" @click="saveVideo"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                <el-link :underline="false" @click="redoVideo"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                说明:在删除记录后不要再新增与编辑,点保存记录就能成功删除
            </span>
        </div>
        <div style=" height:1050px;overflow-y:auto; ">
            <div style="display:flex; justify-content:space-between;width:1250px;flex-wrap:wrap">
                <span v-for="(item,index) in videoData" :key="index" > 
                    <div class="img-contain">
                        <span v-if="item.IMGPATH && item.IMGPATH.indexOf('.mp4')>-1">
                            <div class="video-small">
                            <video-player   class="video-player vjs-custom-skin custom-skin" 
                                            ref="videoPlayer" 
                                            :playsline="false" 
                                            :options="videoConfig[index]">
                            </video-player>
                            </div>
                        </span>
                        <span v-else>
                            <el-link :underline="false">
                                <el-image   :src='item.IMGPATH' fit="scale-down" style="width:298px;height:240px;" ></el-image>
                            </el-link>  
                        </span>
                        <div class="description">
                            <i class="web-font" style="font-size:16px;font-weight:600">{{item.CNAME}}</i>
                        </div>
                    </div>
                </span>
            </div>
            <div>
            <el-table  :data="videoData" 
                border                            
                size="small"
                :height="400" 
                :row-key="getRowKey"
                ref="refvideo"
                highlight-current-row > 
 
                <el-table-column   v-for="(item,index ) in tabCol.filter(item=>item.THIDE=='N')" :key="index" 
                    :prop="item.FLDNO"
        
                    :label="item.FLDNM"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column>    
   
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="160">
                    <template slot-scope="scope">
                        <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="videoEdit(scope.row,scope.$index )">编辑</el-button>
                        <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="videoDel(scope.row,scope.$index )">删除</el-button>  
                    </template>
                </el-table-column>  
            </el-table>
            </div>
        </div>
        <!-- 授权图片 -->
        <el-dialog :visible.sync="authPic" width="1250px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:15px;">更新图片 </span>
            </div>
            <el-image :src="adPath" fit="scale-down" style="width:1230px;height:200px; "></el-image>
            <el-link :underline="false"  >
                <Upload
                    ref="upload"
                    name="upfile"
                    :data="uploadData"
                    :show-upload-list="false"
                    :before-upload="beforeImgFile"
                    :on-success="successImgFile"
                    :on-progress="handleProgress"
                    :accept="Accept"                      
                    :format="['jpg','jpeg','png','gif','webp' ]"
                    :max-size="1024"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :action="uploadUrl"
                    style="display: inline-block;width:90px; ">                           
                    <div style="padding: 20px 20px;width:120px;height:120px;">
                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('horauth')"></el-image> 
                    </div>                                                       
                </Upload> 
            </el-link>
            <div style="height:40px;line-height:30px;border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center;">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="authPic=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="adSave"  style="margin-right:10px;">保存图片</el-button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- pdf & video-->
        <el-dialog  :show-close="false" :visible.sync="pdfWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{authFlag=='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="pdfRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in tabCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE=='N'">                  
                        <el-input-number   v-if="item.EDCSS=='number' "   v-model="pdfRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED=='Y'?true:false"  v-model="pdfRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex;justify-content:space-around">
                    <div style="display:flex;flex-direction:column;width:320px;border:solid 1px #ccc ">
                        <div style="text-align:center;line-height:35px;background:#f2f6fc">图片或视频</div>
                        <div style=" margin-top:10px;">                   
                            <span v-if="upLoad=='IMG'">
                                <el-image  :src="solutePath" style="width:300px;height:280px; " ></el-image>
                            </span>
                            <span v-if="upLoad=='VIDEO'">
                                <div class="video-small">
                                    <video-player   class="video-player vjs-custom-skin custom-skin" 
                                                    ref="videoPlayer" 
                                                    :playsline="false" 
                                                    :options="playerOptions">
                                    </video-player>
                                </div>
                            </span>                                                                         
                        </div>
                        <div  style="margin-top:2px;">
                            <el-row>
                                <el-link :underline="false"  >
                                    <Upload
                                        ref="upload"
                                        name="upfile"
                                        :data="uploadData"
                                        :show-upload-list="false"
                                        :before-upload="beforeImgFile"
                                        :on-success="successImgFile"
                                        :on-progress="handleProgress"
                                        :accept="accVideo"                      
                                        :format="['jpg','jpeg','png','mp4','webp']"
                                        :max-size="30720"
                                        :on-format-error="videoFormat"
                                        :on-exceeded-size="videoMaxSize"
                                        :action="uploadUrl"
                                        style="display: inline-block;width:90px; ">                           
                                        <div style="padding: 20px 20px;width:120px;height:120px;">
                                            <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('video')"></el-image> 
                                        </div>                                                       
                                    </Upload> 
                                </el-link>
                            </el-row>
                        </div> 
                    </div>
                    <!-- 第二列 -->
                    <div style="display:flex;flex-direction:column;width:320px;border:solid 1px #ccc ">
                        <div style="text-align:center;line-height:35px;background:#f2f6fc">PDF 文章</div>
                        <div style=" margin-top:10px;">                   
                            
                            <div style="width:300px;height:260px;border:1px solid #e8eaec;overflow-y:hidden">
                                <iframe :src="pdfPath" frameborder="0" style="width: 100%; height: 100%"></iframe>
                            </div>
                            <div style="height:35px;background:#e8eaec;width:300px; display:flex;justify-content:center">
                                <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(pdfPath)"><Icon type="ios-search" />查看</el-link>
                            </div>                                            
                        </div>
                        <div  style="margin-top:2px;">
                            <el-row>
                                <el-link :underline="false"  >
                                    <Upload
                                        ref="upload"
                                        name="upfile"
                                        :data="uploadData"
                                        :show-upload-list="false"
                                        :before-upload="beforeImgFile"
                                        :on-success="successImgFile"
                                        :on-progress="handleProgress"
                                        :accept="accPdf"                      
                                        :format="['pdf']"
                                        :max-size="10240"
                                        :on-format-error="pdfFormat"
                                        :on-exceeded-size="pdfMaxSize"
                                        :action="uploadUrl"
                                        style="display: inline-block;width:90px; ">                           
                                        <div style="padding: 20px 20px;width:120px;height:120px;">
                                            <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('file')"></el-image> 
                                        </div>                                                       
                                    </Upload> 
                                </el-link>
                            </el-row>
                        </div> 
                    </div>
                </div>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="pdfWin=false" style="margin-right:50px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="pdfAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { getBaseData,getTabColOrData } from '../../api/user'
    import {  uuid } from '../../api/Tools'
    //数组元素重新按某个属性排序
    var compareObj=function(prop){
        return function(obj1,obj2){
            var val1 =obj1[prop]?Number(obj1[prop]):0
            var val2 =obj2[prop]?Number(obj2[prop]):0
            if (val1<val2){
                return -1
            }else if(val1>val2){
                return 1
            }else{
                return 0
            }
        }
    }
    export default {
        data () {
            return {
                solutePath:'',
                upLoad:'',
                pdfPath:'',
                pdfRow:{},
                authFlag:'create',
                pdfWin:false,
                authPic:false,
                upFlag:'',
                tabCol:[],
                adPath:'',
                advertPath:'',  
                uploadData:{},  
                Accept: "png,jpg,jpeg,webp", //上传文件格式限制
                accVideo: "png,jpg,jpeg,webp,mp4", //上传文件格式限制
                accPdf: "pdf", //上传文件格式限制
                videoFlag:false,
                videoUploadPercent:0,
                promptWin:false,
                prompt:'',
                uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
                v_url:this.$store.state.queryUrl , //api请求路径    
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                videoData:[],
                videoConfig:[],
                pdfIndex:'',
                pdfFlag:'create',
                pdfBef:{},
                delPdfArr:[],
                playerOptions: {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                    autoplay: false, // 如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: '' // url地址 /assets/authorize/tmp4.mp4
                    }],
                    poster: '', // 你的封面地址
                    //width: '400px', // 播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true // 全屏按钮
                    }
                },
            }
        },
        mounted () {
            this.getAdvert()  
            this.getVideoList()
            this.getTabCol()
        },
        methods: {
            //预览PDF
            pdfViewer(filePath){
                let routeUrl = this.$router.resolve({
                    path: '/fullPreview',
                    query: {lang:this.LANG,fileUrl:filePath},
                });
                window.open(routeUrl.href, '_blank');
            },
            pdfAppend(){
                if (this.pdfFlag=='create'){
                    this.videoData.push(JSON.parse(JSON.stringify(this.pdfRow)))
                }else{
                    this.videoData.splice(this.pdfIndex,1,JSON.parse(JSON.stringify(Object.assign(this.pdfBef,this.pdfRow))) )
                }  
                this.videoData.sort(compareObj('SORTBY'))
                this.pdfWin=false
                this.$forceUpdate()
            },
            getRowKey(row){
                return row.IDSEQ+'-'+row.LSTSEQ
            },
            videoEdit(row,index){
                this.pdfFlag='edit'
                this.pdfRow=JSON.parse(JSON.stringify(row))
                this.pdfWin=true
                this.pdfIndex=index
                this.pdfBef=row
                //this.delPdfArr=[]
                if (row['IMGPATH'] && row['IMGPATH'].indexOf('.mp4')>-1){
                    this.upLoad='VIDEO'
                    this.playerOptions.sources[0].src = row.IMGPATH
                }else{
                    this.upLoad='IMG'
                    this.solutePath=row.IMGPATH
                }
                this.pdfPath=row['PDFPATH']
            },
            videoDel(row,index){
                this.delPdfArr.push(row)
                this.videoData.splice(index,1 )
            },
            //VIDEO & ARTICLE
            getVideoList(){
                this.videoData=[]
                getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','VIDEO','','','','',' order by sortby,lstseq' ).then((res) => { 
                    this.videoData =res.data                     
                    for(let k=0;k<this.videoData.length;k++){
                        this.videoConfig.push({
                            playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                            autoplay: false, // 如果true,浏览器准备好时开始回放。
                            muted: false, // 默认情况下将会消除任何音频。
                            loop: false, // 导致视频一结束就重新开始。
                            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                            language: 'zh-CN',
                            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                            sources: [{
                                type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                                src: this.videoData[k].IMGPATH // url地址 /assets/authorize/tmp4.mp4
                            }],
                            poster: '', // 你的封面地址
                            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                            controlBar: {
                                timeDivider: true,
                                durationDisplay: true,
                                remainingTimeDisplay: false,
                                fullscreenToggle: true // 全屏按钮
                            }
                        })
                    }
                    setTimeout(() => {
                        this.$refs['refvideo'].doLayout();
                    }, 500);
 
                })   
            },
            addVideo(){
                this.pdfRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'VIDEO',PDFPATH:'',IMGPATH:''}
                this.solutePath=''
                this.pdfPath=''
                this.pdfWin=true
                this.pdfFlag='create'
            },
            saveVideo(){
                this.$confirm('你确定要保存视频文章吗?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    //批量保存
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                        data: {p_json:JSON.stringify(this.videoData), p_table:'bs_img',p_idseq:'38618' },
                    }).then(res=>{
                        if (res.data.code =='200'){
                            this.getVideoList()
  
                            this.promptWin=true
                            this.prompt='已保存'
                           
                        }
                    })
                    //批量删除
                    for (let k=0;k<this.delPdfArr.length;k++) {
                        this.$axios({   
                            method: 'post',
                            url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                            data: {p_key:'idseq',p_value:this.delPdfArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delPdfArr[k].LSTSEQ, p_table:'bs_img'},
                        })
                    } 
                }).catch(()=>{})
            },
            redoVideo(){
                this.$confirm('你确定要取消保存视频或文章资料吗?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    this.getVideoList()
                }).catch(()=>{})
            },
            upType(val){
                this.upFlag=val
            },
            getAdvert(){
                getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','SOLUTEIMG','','','','',' order by sortby,lstseq' ).then((res) => { 
                    this.advertPath =res.data[0].IMGPATH 
                })
            },
            advertEdit(){
                this.authPic=true
                this.adPath=this.advertPath
            },
            //上传文件之前的钩子
            beforeImgFile (res, file) {  
                this.uploadData = {
                    p_user:this.v_username,
                    p_idseq:  uuid(),
                    p_table:'',
                    p_filety:'',
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            },
            // 文件上传成功时的钩子
            successImgFile(res,file){
                this.videoFlag = false
                this.videoUploadPercent=0
                if (this.upFlag=='horauth'){
                    this.adPath=res.result
                }else if (this.upFlag=='video'){
                    this.solutePath=res.result
                    this.pdfRow['IMGPATH']=res.result
                }else if (this.upFlag=='file'){
                    this.pdfPath=res.result
                    this.pdfRow['PDFPATH']=res.result
                }
            },
            //上传显示进度
            handleProgress(event,file,fileList){
                this.videoFlag = true
                this.videoUploadPercent = Math.floor(event.percent)
            },
            //文件超出指定文件大小限制的钩子
            handleMaxSize (file) {
                this.prompt =file.name + ' 文件太大, 不能超出1M'
                this.promptWin=true
            },
            videoMaxSize(file) {
                this.prompt =file.name + ' 文件太大, 不能超出30M'
                this.promptWin=true
            },
            pdfMaxSize(file) {
                this.prompt =file.name + ' 文件太大, 不能超出8M'
                this.promptWin=true
            },
            //文件格式验证错误时的钩子
            handleFormatError (file) {
                this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
                this.promptWin=true
            }, 
            videoFormat(file) {
                this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png or mp4' 
                this.promptWin=true
            }, 
            pdfFormat(file) {
                this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 pdf' 
                this.promptWin=true
            }, 
            adSave(){
                this.$confirm('你确定要更新此图片到前端吗' ,'提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'auth/login/updateRec' ,
                        data: {p_key:'idseq',p_value:'38618',p_key2:'lstseq',p_value2:'65',p_table:'BS_img',p_cond:' set imgpath=\''+this.adPath+'\' '},
                    }).then(res=>{      
                        if(res.data.code =='200')  {           
                            this.authPic=false
                            this.advertPath=this.adPath
                        }     
                    })
                }).catch(()=>{})
            },
            getTabCol(){
                //轮播图
                getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','38738','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                    this.tabCol = res.data
                })
            }
        }
    }
    
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .el-aside {
        height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
    }
    .card-div {
        padding: 14px;
        background:#EBEEF5; 
        text-align:center;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .img-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        line-height:20px;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
    .group-css{
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      width:1230px;
    }
    .wraper{
        width:1250px;
        margin:1px auto;
    }
    .description {
        width: 100%;
        position:absolute; 
        z-index:2;
        left:0;
        bottom:0;
        text-align: center;
        line-height:35px;
        color:#3311f7;
        background-color: rgb(183, 233, 235);
    }
    .img-contain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-left:2px;
        border:1px solid #ccc;
        position: relative;
        width: 300px;
        height: 280px;
    }
    .video-small {
        height: 278px;
        width: 298px;
        // background: rgba(247, 234, 234, 0.856);
        padding:5px auto;
        border-radius: 10px;
        }
 
 
</style>