<template>
    <div  > 
        <div style=" height:525px;overflow-y:auto;">
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                <span style="font-size:14px"> 一、推广图 ( 提示：保存后将会改变前端界面，图片大小：1250x320 )
                    <el-link :underline="false" @click="addBan"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增图片】</i></el-link>
                    <el-link :underline="false" @click="saveBan"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存图片】</i></el-link>
                    <el-link :underline="false" @click="redoBan"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                </span>
                <div style="width:1250px;margin-top:5px;margin-bottom:2px;">
                    <el-carousel height='320px;'>
                        <el-carousel-item arrow="always" v-for="(item, index) in banData" :key="index">
                            <el-image   :src='item.IMGPATH' fit="fill" style="width:100%;height:100%;" ></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <el-table  :data="banData" 
                    border                            
                    size="small"
                    :height="175" 
                    :row-key="getRowKey"
                    ref="refdtl"
                    highlight-current-row > 
                    <span v-for="(item,index ) in banCol" :key="index" > 
                        <el-table-column   v-if="item.THIDE=='N'"
                            :prop="item.FLDNO"
                            :fixed="item.key==='SORTBY'"
                            :label="item.FLDNM"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="160">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="banEdit(scope.row,scope.$index )">编辑</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="banDel(scope.row,scope.$index )">删除</el-button>  
                        </template>
                    </el-table-column>  
                </el-table>
            </div>
        </div>
        <div style=" height:560px;overflow-y:auto;">         
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                <span style="font-size:14px"> 二、授权代理证书 ( 提示：保存后将会改变前端界面,证书：480x600,下面图标：130x40 )
                    <el-link :underline="false" @click="addHot"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增代理商证书】</i></el-link>
                    <el-link :underline="false" @click="saveHot"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                    <el-link :underline="false" @click="redoHot"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                </span>   
            </div>
            <div class="group-css">
                <div style="margin-top:10px;justify-content:flex-start "  >  
                    <el-card :body-style="{ padding: '0px',width:'1228px',height:'250px' }"  >                    
                        <div style="display:flex; ">
                            <div style="margin:100px auto; float:left;height:250px;width:50px; ">
                                <el-link @click="leftNav" :underline="false" class="el-icon-arrow-left" style="font-size:30px;"></el-link>
                            </div>
                            <div v-for="(item, index) in certArr" :key="index"  style="width:1130px;height:270px;display:flex;justify-content:flex-start">
                                <div style="display:flex;flex-direction:column">
                                    <el-tooltip  effect="light"  placement="right" style="margin:4px;cursor:pointer" popper-class="atooltip">
                                        <el-image   :src='item.IMGPATH'  fit="fill" style="width:160px;height:200px;" ></el-image>
                                        <template slot="content" >
                                            <el-image   fit="fill" style="width:480px;height:600px; text-align:center; " :src="item.IMGPATH"></el-image>        
                                        </template>
                                    </el-tooltip>
                                    <!-- 证书下面的描述图片 -->
                                    <div v-if="item.ICONPATH" :title="item.CNAME">
                                        <el-image  :src='item.ICONPATH'  fit="scale-down"  style="width:130px;height:40px;padding-top:2px;padding-bottom:2px; "></el-image>
                                    </div>
                                </div>
                            </div>    
                            <div style="margin:100px auto; float:right;height:250px;width:50px;">
                                <el-link @click="rightNav" :underline="false" class="el-icon-arrow-right" style="font-size:30px;"></el-link>
                            </div> 
                        </div>
                    </el-card>
                </div>
            </div>
            <el-table  :data="hotData" 
                border                            
                size="small"
                :height="240" 
                :row-key="getRowKey"
                ref="refhot"
                highlight-current-row > 
                <span v-for="(item,index ) in banCol" :key="index" > 
                    <el-table-column   v-if="item.THIDE=='N'"
                        :prop="item.FLDNO"
                        :fixed="item.key==='SORTBY'"
                        :label="item.FLDNM"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>    
                </span> 
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="160">
                    <template slot-scope="scope">
                        <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="hotEdit(scope.row,scope.$index )">编辑</el-button>
                        <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="hotDel(scope.row,scope.$index )">删除</el-button>  
                    </template>
                </el-table-column>  
            </el-table>
        </div>
        <div style=" height:495px;overflow-y:auto;"> 
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                <span style="font-size:14px"> 三、战略合作( 提示：保存后将会改变前端界面,尺寸：260x170 )
                    <el-link :underline="false" @click="addFty"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增记录】</i></el-link>
                    <el-link :underline="false" @click="saveFty"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                    <el-link :underline="false" @click="redoFty"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                </span> 
            </div>
            <div class="group-css">
                <el-card :body-style="{ padding: '0px',width:'1228px',height:'200px',display:'flex','justify-content':'space-between'  }"  > 
                    <div   v-for="(item, index) in ftyData" :key="index" style="margin-right:55px;margin-top:15px; " >        
                        <el-link :underline="false" >
                            <el-card  :body-style="{ padding: '0px',width:'260px',height:'170px' }" class="cardhover">                    
                                <el-image   :src='item.IMGPATH'  fit="scale-down" style="height:130px;width:100%" ></el-image>
                                <div style="padding: 7px;background:#EBEEF5; text-align:center">
                                    <span style="font-size:12px;color:#999">{{ item.CNAME}}</span> 
                                </div>
                            </el-card>
                        </el-link>
                    </div>
                </el-card>
            </div>
            <el-table  :data="ftyData" 
                border                            
                size="small"
                :height="220" 
                :row-key="getRowKey"
                ref="reffty"
                highlight-current-row > 
                <span v-for="(item,index ) in ftyCol" :key="index" > 
                    <el-table-column   v-if="item.THIDE=='N'"
                        :prop="item.FLDNO"
                        :fixed="item.key==='SORTBY'"
                        :label="item.FLDNM"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>    
                </span> 
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="160">
                    <template slot-scope="scope">
                        <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="ftyEdit(scope.row,scope.$index )">编辑</el-button>
                        <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="ftyDel(scope.row,scope.$index )">删除</el-button>  
                    </template>
                </el-table-column>  
            </el-table>
        </div>
        <div style=" height:480px;overflow-y:auto;"> 
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                <span style="font-size:14px"> 四、热销品牌( 提示：保存后将会改变前端界面,尺寸：260x140 )
                    <el-link :underline="false" @click="addAuth"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增记录】</i></el-link>
                    <el-link :underline="false" @click="saveAuth"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                    <el-link :underline="false" @click="redoAuth"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>

                </span> 
            </div>
            <div class="group-css">
                <el-card :body-style="{ padding: '0px',width:'1228px',height:'200px',display:'flex','justify-content':'space-between'  }"  >     
                    <div style="margin-top:10px;margin-right:55px;" v-for="(item, index) in authData" :key="index"  >
                        <el-link :underline="false" >
                            <el-card :body-style="{ padding: '0px',width:'260px',height:'170px' }" class="cardhover">                    
                                <el-image   :src='item.IMGPATH'  fit="scale-down" style="height:130px;width:258px;"  ></el-image>
                                <div style="line-height:35px;background:#EBEEF5; text-align:center">
                                    <span style="font-size:12px;color:#999; ">{{ item.CNAME}}</span> 
                                </div>
                            </el-card>
                        </el-link>
                    </div>
                </el-card>
            </div>
            <el-table  :data="authData" 
                border                            
                size="small"
                :height="220" 
                :row-key="getRowKey"
                ref="refauth"
                highlight-current-row > 
                <span v-for="(item,index ) in ftyCol" :key="index" > 
                    <el-table-column   v-if="item.THIDE=='N'"
                        :prop="item.FLDNO"
                        :fixed="item.key==='SORTBY'"
                        :label="item.FLDNM"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>    
                </span> 
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="160">
                    <template slot-scope="scope">
                        <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="authEdit(scope.row,scope.$index )">编辑</el-button>
                        <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="authDel(scope.row,scope.$index )">删除</el-button>  
                    </template>
                </el-table-column>  
            </el-table>
        </div>
 
        <!--  推广编辑 -->
        <el-dialog  :show-close="false" :visible.sync="banWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{banFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="banRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in banCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="banRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="banRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="banPath" style="width:120px;height:120px;margin-right:20px;margin-left:120px;" ></el-image>
                        </el-row> 
                        <el-row > 
                            <el-link   :underline="false" @click="imgPreview('ban')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-left:20px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('ban')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
 
                    </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:30px;border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center;">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="banWin=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="banAppend"  style="margin-right:10px;">确定</el-button>
            </div>
        </el-dialog>
        <!--  授权代理编辑 -->
        <el-dialog  :show-close="false" :visible.sync="hotWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{hotFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="hotRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in banCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                     
                        <el-select  v-if="item.FLDNO==='SUPNO'" @change ="supChange" v-model="hotRow[item.FLDNO]" style="width:100%" filterable clearable>
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                        </el-select>
                        <el-input-number   v-else-if="item.EDCSS==='number' "   v-model="hotRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="hotRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="certPath" style="width:120px;height:120px;margin-right:20px;margin-left:120px;" ></el-image>
                        </el-row> 
                        <el-row >
                            <el-link   :underline="false" @click="imgPreview('cert')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-top:2px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('cert')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                     </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:30px;border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center;">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="hotWin=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="hotAppend"  style="margin-right:10px;">确定</el-button>
            </div>
        </el-dialog>
 
        
        <!-- 预览图片 -->
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="prevPath"  style="width:99%;height:400px;margin-top:5px;"></el-image> 
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
 
        <!--  战略合作-->
        <el-dialog  :show-close="false" :visible.sync="ftyWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{banFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="ftyRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in ftyCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="ftyRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select  v-else-if="item.FLDNO==='SUPNO'" @change="supCop" v-model="ftyRow[item.FLDNO]" style="width:100%" filterable clearable>
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="ftyRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="ftyPath" style="width:120px;height:120px;margin-right:20px;margin-left:120px;" ></el-image>
                        </el-row> 
                        <el-row >
                            <el-link   :underline="false" @click="imgPreview('coop')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-top:2px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('coop')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                     </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="ftyWin=false" style="margin-right:50px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="ftyAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
        <!-- 热销品牌-->
        <el-dialog  :show-close="false" :visible.sync="authWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{authFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="authRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in ftyCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="authRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select  v-else-if="item.FLDNO==='SUPNO'" @change="supSel" v-model="authRow[item.FLDNO]" style="width:100%" filterable clearable>
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="authRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="authPath" style="width:120px;height:120px;margin-right:20px;margin-left:120px;" ></el-image>
                        </el-row> 
                        <el-row >
                            <el-link   :underline="false" @click="imgPreview('auth')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-top:2px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('auth')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                     </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="authWin=false" style="margin-right:50px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="authAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
    </div>  
</template>
<script>
 
import { getBaseData,getTabColOrData } from '../../api/user'
import {  paramData ,paramData2} from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
//数组元素重新按某个属性排序
var compareObj=function(prop){
    return function(obj1,obj2){
        var val1 =obj1[prop]?Number(obj1[prop]):0
        var val2 =obj2[prop]?Number(obj2[prop]):0
        if (val1<val2){
            return -1
        }else if(val1>val2){
            return 1
        }else{
            return 0
        }
    }
}
//局部注册
export default {
    data () {
        return {
            supArr:[], //供应商集合
            Accept: "png,jpg,jpeg,webp,gif", //上传文件格式限制
            uploadData:{}, //上传数据
            videoFlag:false,
            videoUploadPercent:0,
            upFlag:'',
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            banPath:'',
            ftyPath:'',
            authPath:'',
            showViewer:false,
            prevPath:'',
            banCol:[],
            banData:[],
            banWin:false,
            banFlag:'create',
            banIndex:'',
            banRow:{},
            banBef:{},
            delBanArr:[],
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            hotData:[],
 
            hotIndex:'',
            hotFlag:'create',
            hotBef:{},
            hotRow:{},
            hotWin:false,
            delHotArr:[],
            ftyData:[],
            ftyCol:[],
            ftyWin:false,
            ftyRow:{},
            ftyFlag:'create',
            ftyIndex:'',
            ftyBef:{},
            delFtyArr:[],
            authData:[],
            authCol:[],
            authWin:false,
            authRow:{},
            authFlag:'create',
            authIndex:'',
            authBef:{},
            delAuthArr:[],
            advertPath:'',
            certPath:'',
            certCount:0,
            certArr:[], //证书   
            pageSize:5, //证书每页显示数量
            index:1,
        }
    },
    mounted () {   
        this.getBanList()
        this.getHotList()
        this.getFtyList()
        this.getAuthList()
        this.getAdvert()
        //供应商数据
        paramData('STFG','Y' ,'V_COMBO_SUP').then((res)=>{               
            this.supArr =res.data.result
        }); 
    },
    computed: {
 
        bodyHeight: function() {
            return  '99vh';
        }, 
    },
    components: {
 
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
  
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
 
    },
    methods: { 
        //选择供应商时，名称赋值
        supCop(val){
            let obj =this.supArr.find(item=>{
                return item.IDNO===val
            })
            this.ftyRow['SUPNM'] =obj['SUPNM']  
        },
        supSel(val){
            let obj =this.supArr.find(item=>{
                return item.IDNO===val
            })
            this.authRow['SUPNM'] =obj['SUPNM']  
        },
        supChange(val){
            let obj =this.supArr.find(item=>{
                return item.IDNO===val
            })
            this.hotRow['SUPNM'] =obj['SUPNM']  
            this.hotRow['SUPNO'] =obj['IDNO']           
            this.hotRow['ICONPATH'] =obj['ICONPATH']  
        },
        //向左切换
        leftNav(){
            if (this.index===0){
                this.index=Math.ceil(this.certCount/this.pageSize)
            } 
            this.index--
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_start<=0){
                this.index=1
                this.certArr = this.hotData.slice(0,this.pageSize);
            }else{
                this.certArr = this.hotData.slice(_start,_end);
            }
 
        },
        //向右切换
        rightNav(){
            this.index++
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_end>this.certCount){
                this.certArr = this.hotData.slice(_start,_end);
                this.index=0
            }else{
                this.certArr = this.hotData.slice(_start,_end);
            }
        },
        upType(val){
            this.upFlag=val
        },
 
        //推广保存
        saveBan(){
            this.$confirm('你确定要保存推广图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.banData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delBanArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delBanArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delBanArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //授权代理
        saveHot(){
            this.$confirm('你确定要保存授权代理吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.hotData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delHotArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delHotArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delHotArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //战略合作
        saveFty(){
            this.$confirm('你确定要保存战略合作吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.ftyData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delFtyArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delFtyArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delFtyArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //热销品牌
        saveAuth(){
            this.$confirm('你确定要保存热销品牌吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.authData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delAuthArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delAuthArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delAuthArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        addBan(){
            this.banRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'POWER'}
            this.banWin=true
            this.banFlag='create'
            this.banPath=''
        },
        addHot(){
            this.hotRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'CERT',IMGPATH:''}
            this.certPath =''
            this.hotWin=true
            this.hotFlag='create'
        },
        addFty(){
            this.ftyRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'COOP'}
            this.ftyWin=true
            this.ftyFlag='create'
            this.ftyPath=''
        },
        addAuth(){
            this.authRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'HBAND'}
            this.authWin=true
            this.authFlag='create'
            this.authPath=''
        },
        redoBan(){
            this.$confirm('你确定要取消保存推广图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getBanList()
            }).catch(()=>{})
        },
        redoHot(){
            this.$confirm('你确定要取消保存授权代理资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getHotList()
            }).catch(()=>{})
        },
        redoFty(){
            this.$confirm('你确定要取消保存战略合作资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getFtyList()
            }).catch(()=>{})
        },
        redoAuth(){
            this.$confirm('你确定要取消保存热销品牌资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getFtyList()
            }).catch(()=>{})
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出1M'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
            this.promptWin=true
        }, 
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            if (this.upFlag=='ban'){
                this.banPath=res.result
                this.banRow['IMGPATH']=res.result
            }else if (this.upFlag=='coop'){
                this.ftyPath=res.result
                this.ftyRow['IMGPATH']=res.result
            }else if (this.upFlag=='auth'){
                this.authPath=res.result
                this.authRow['IMGPATH']=res.result
            }else if (this.upFlag=='cert'){
                this.certPath=res.result
                this.hotRow['IMGPATH']=res.result
            }
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            this.uploadData = {
                p_user:this.v_username,
                p_idseq:  uuid(),
                p_table:'',
                p_filety:'',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        imgPreview(val){
            if (val=='coop'){
                this.prevPath=this.ftyRow['IMGPATH']
            }else if (val=='ban'){
                this.prevPath=this.banRow['IMGPATH']
            }else if (val=='auth'){
                this.prevPath=this.authRow['IMGPATH']
            }else if (val=='cert'){
                this.prevPath=this.hotRow['IMGPATH']
            }
            this.showViewer=true
        },
        banAppend(){
            if (this.banFlag==='create'){
                this.banData.push(this.banRow)
            }else{
                this.banData.splice(this.banIndex,1,Object.assign(this.banBef,this.banRow) )
            }  
            this.banData.sort(compareObj('SORTBY'))
            this.banWin=false
        },
        hotAppend(){
            if (this.hotFlag==='create'){
                this.hotData.push(this.hotRow)
            }else{
                this.hotData.splice(this.hotIndex,1,Object.assign(this.hotBef,this.hotRow) )
            }
            this.hotData.sort(compareObj('SORTBY'))
            this.hotWin=false
        },
        ftyAppend(){
            if (this.ftyFlag==='create'){
                this.ftyData.push(this.ftyRow)
            }else{
                this.ftyData.splice(this.ftyIndex,1,Object.assign(this.ftyBef,this.ftyRow) )
            }  
            this.ftyData.sort(compareObj('SORTBY'))
            this.ftyWin=false
        },
        authAppend(){
            if (this.authFlag==='create'){
                this.authData.push(this.authRow)
            }else{
                this.authData.splice(this.authIndex,1,Object.assign(this.authBef,this.authRow) )
            }  
            this.authData.sort(compareObj('SORTBY'))
            this.authWin=false
        },
        getRowKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        getTabCol(){
            //推广图
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41190','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.banCol = res.data
            })
 
            //战略合作
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41212','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.ftyCol = res.data
            })
        },
        banDel(row,index){
            this.delBanArr.push(row)
            this.banData.splice(index,1 )
        },
        hotDel(row,index){
            this.delHotArr.push(row)
            this.hotData.splice(index,1 )
        },
        ftyDel(row,index){
            this.delFtyArr.push(row)
            this.ftyData.splice(index,1 )
        },
        authDel(row,index){
            this.delAuthArr.push(row)
            this.authData.splice(index,1 )
        },
        banEdit(row,index){
            this.banFlag='edit'
            this.banRow=JSON.parse(JSON.stringify(row))
            this.banWin=true
            this.banIndex=index
            this.banBef=row
            this.banPath=row['IMGPATH']
            this.delBanArr=[]
        },
        hotEdit(row,index){
            this.hotFlag='edit'
            this.hotRow=JSON.parse(JSON.stringify(row))
            this.hotWin=true
            this.hotIndex=index
            this.hotBef=row
            this.delHotArr=[]
            this.certPath=row['IMGPATH']
        },
        ftyEdit(row,index){
            this.ftyFlag='edit'
            this.ftyRow=JSON.parse(JSON.stringify(row))
            this.ftyWin=true
            this.ftyIndex=index
            this.ftyBef=row
            this.delFtyArr=[]
            this.ftyPath=row['IMGPATH']
        },
        authEdit(row,index){
            this.authFlag='edit'
            this.authRow=JSON.parse(JSON.stringify(row))
            this.authWin=true
            this.authIndex=index
            this.authBef=row
            this.delAuthArr=[]
            this.authPath=row['IMGPATH']
        },
        //推广明细 
        getBanList(){
            this.banData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','POWER','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.banData =res.data 
            })
            setTimeout(() => {
                this.$refs.refdtl.doLayout();
            }, 500);
        },
        //代理证书 
        getHotList(){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','CERT','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.hotData =res.data
                this.certCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < this.pageSize){
                    this.certArr = this.hotData
                }else{
                    this.certArr =this.hotData.slice(0,this.pageSize) //返回选定元素的子数组，不包含结尾元素
                }
            })
            setTimeout(() => {
                this.$refs.refhot.doLayout();
            }, 500);
        },
        //战略合作
        getFtyList(){
            this.ftyData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','COOP','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.ftyData =res.data 
            })
            setTimeout(() => {
                this.$refs.reffty.doLayout();
            }, 500);
        },
        //热销品牌
        getAuthList(){
            this.authData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','HBAND','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.authData =res.data 
            })
            setTimeout(() => {
                this.$refs.refauth.doLayout();
            }, 500);
        },
        getAdvert(){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','ADVERT','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.advertPath =res.data[0].IMGPATH 
            })
        },
    }
}
</script>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    .el-aside {
        height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
    }
    .card-div {
        padding: 14px;
        background:#EBEEF5; 
        text-align:center;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .img-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        line-height:20px;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
    .group-css{
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      width:1230px;
    }
    .wraper{
        width:1250px;
        margin:1px auto;
    }
 
</style>
 