<template>
    <div style="overflow-y:auto;height:90vh"> 
        <el-tabs :animated="false" style="background:#fff">
            <el-tab-pane> 
                <span slot="label" style="padding-left:15px;"><i class="iconfont icon-yiqueren-xianxing"></i>首页</span>
                <div style=" height:785px;overflow-y:auto;">  <!--总高-->
                    <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                        <span style="font-size:14px;"> 一、轮播图  ( 提示：保存后将会改变前端界面！尺寸1100x480,最大400kb,最多8张！上传一个保存一个！)  
                            <el-link :underline="false" @click="addBan"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增图片】</i></el-link>
                            <el-link :underline="false" @click="saveBan"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存图片】</i></el-link>
                            <el-link :underline="false" @click="redoBan"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                        </span>
                        <div style="width:1058px;margin-top:5px;margin-bottom:2px;padding-left:500px;">
                            <el-row>
                                <el-col  >     
                                    <el-carousel height='370px;'>
                                        <el-carousel-item  v-for="(item, index) in banData" :key="index">
                                            <el-image   :src='item.IMGPATH' fit="fill" style="width:fit-content;height:100%;" ></el-image>
                                        </el-carousel-item>
                                    </el-carousel>
                                </el-col>
                            </el-row>
                        </div>
                        <div>
                        <el-table  :data="banData" 
                            border                            
                            size="small"
                            :height="415" 
                            :row-key="getRowKey"
                            ref="refdtl"
                            highlight-current-row > 
                            <span v-for="(item,index ) in banCol" :key="index" > 
                                <el-table-column   v-if="item.THIDE=='N'"
                                    :prop="item.FLDNO"
                    
                                    :label="item.FLDNM"
                                    show-overflow-tooltip
                                    :align="item.align"                                  
                                    sortable
                                    :min-width="item.width">
                                </el-table-column>    
                            </span> 
                            <el-table-column
                                fixed="right"
                                label="操作"
                                align="center"
                                width="160">
                                <template slot-scope="scope">
                                    <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="banEdit(scope.row,scope.$index )">编辑</el-button>
                                    <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="banDel(scope.row,scope.$index )">删除</el-button>  
                                </template>
                            </el-table-column>  
                        </el-table>
                        </div>
                    </div>
                </div>
                <div style=" height:695px;overflow-y:auto;">         
                    <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
                        <span style="font-size:14px"> 二、热销产品 ( 提示：保存后将会改变前端界面, 尺寸：190x120 )
                            <el-link :underline="false" @click="addHot"><i class="iconfont icon-xinzeng" style="padding-left:20px;font-size:15px;color:white">【新增产品】</i></el-link>
                            <el-link :underline="false" @click="saveHot"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                            <el-link :underline="false" @click="redoHot"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                        </span>   
                    </div>
                    <div class="group-css">
                        <div style="margin-top:2px; " v-for="(item, index) in hotData" :key="index"  >
                            <el-link :underline="false" >
                                <el-card :body-style="{ padding: '0px',width:'192px',height:'200px' }" class="cardhover" >                    
                                    <el-image   :src='item.IMGPATH'  fit="scale-down" style="width:188px;height:120px;" ></el-image>
                                    <div class="card-div">
                                        <span style="font-size:12px;color:#999">{{ item.IDNO}}</span> 
                                        <div class="img-div">
                                            <span style="font-size:12px;color:#999" :title="item.MATDESC">{{ item.MATDESC}}</span> 
                                        </div>
                                        <div class="img-div">
                                            <span style="font-size:14px;color:red; ">  ¥{{ item.TAXPRC}}</span> 
                                        </div>
                                    </div>
                                </el-card>
                            </el-link>
                        </div>
                    </div>
                    <div>
                    <el-table  :data="hotData" 
                        border                            
                        size="small"
                        :height="240" 
                        :row-key="getRowKey"
                        ref="refhot"
                        highlight-current-row > 
                        <span v-for="(item,index ) in hotCol" :key="index" > 
                            <el-table-column   v-if="item.THIDE=='N'"
                                :prop="item.FLDNO"
                    
                                :label="item.FLDNM"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable
                                :min-width="item.width">
                            </el-table-column>    
                        </span> 
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="160">
                            <template slot-scope="scope">
                                <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="hotEdit(scope.row,scope.$index )">编辑</el-button>
                                <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="hotDel(scope.row,scope.$index )">删除</el-button>  
                            </template>
                        </el-table-column>  
                    </el-table>
                    </div>
                </div>
                <div style=" height:695px;overflow-y:auto;"> 
                    <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
                        <span style="font-size:14px"> 三、原厂对接 ( 提示：保存后将会改变前端界面, 图片大小：260x160 )
                            <el-link :underline="false" @click="addFty"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增原厂】</i></el-link>
                            <el-link :underline="false" @click="saveFty"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                            <el-link :underline="false" @click="redoFty"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                        </span> 
                    </div>
                    <div class="group-css">
                        <div style="margin-top:10px; " v-for="(item, index) in ftyData" :key="index"  >
                            <el-card :body-style="{ padding: '0px',width:'260px',height:'200px' }" class="cardhover">                    
                                <el-image   :src='item.IMGPATH'  fit="scale-down" style="width:100%;height:160px;" ></el-image>
                                <div style="padding: 9px;background:#EBEEF5; text-align:center">
                                    <span style="font-size:12px;color:#999">{{ item.CNAME}}</span> 
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <div>
                    <el-table  :data="ftyData" 
                        border                            
                        size="small"
                        :height="220" 
                        :row-key="getRowKey"
                        ref="reffty"
                        highlight-current-row > 
                        <span v-for="(item,index ) in ftyCol" :key="index" > 
                            <el-table-column   v-if="item.THIDE=='N'"
                                :prop="item.FLDNO"
           
                                :label="item.FLDNM"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable
                                :min-width="item.width">
                            </el-table-column>    
                        </span> 
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="160">
                            <template slot-scope="scope">
                                <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="ftyEdit(scope.row,scope.$index )">编辑</el-button>
                                <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="ftyDel(scope.row,scope.$index )">删除</el-button>  
                            </template>
                        </el-table-column>  
                    </el-table>
                    </div>
                </div>
                <div style=" height:855px;overflow-y:auto;"> 
                    <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
                        <span style="font-size:14px"> 四、授权厂商( 提示：保存后将会改变前端界面,尺寸：260x160)
                            <el-link :underline="false" @click="advertEdit"><i class="iconfont icon-bianji" style="font-size:15px;color:#8a1c1c;padding-left:20px;padding-right:30px;">【更新横条图】</i></el-link>
                            <el-link :underline="false" @click="addAuth"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:5px;">【新增记录-授权商】</i></el-link>
                            <el-link :underline="false" @click="saveAuth"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                            <el-link :underline="false" @click="redoAuth"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                            
                        </span> 
                    </div>
                    <div class="group-css">
                        <el-image :src="advertPath" fit="fill" style="width:1230px;height:120px; "></el-image>
                        <div style="margin-top:10px; " v-for="(item, index) in authData" :key="index"  >
                            <el-card :body-style="{ padding: '0px',width:'260px',height:'200px' }" class="cardhover">                    
                                <el-image   :src='item.IMGPATH'  fit="scale-down" style="width:100%;height:160px;" ></el-image>
                                <div style="padding: 9px;background:#EBEEF5; text-align:center; ">
                                    <span style="font-size:12px;color:#999">{{ item.CNAME}}</span> 
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <div>
                    <el-table  :data="authData" 
                        border                            
                        size="small"
                        :height="220" 
                        :row-key="getRowKey"
                        ref="refauth"
                        highlight-current-row > 
                        <span v-for="(item,index ) in ftyCol" :key="index" > 
                            <el-table-column   v-if="item.THIDE=='N'"
                                :prop="item.FLDNO"
                    
                                :label="item.FLDNM"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable
                                :min-width="item.width">
                            </el-table-column>    
                        </span> 
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="160">
                            <template slot-scope="scope">
                                <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="authEdit(scope.row,scope.$index )">编辑</el-button>
                                <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="authDel(scope.row,scope.$index )">删除</el-button>  
                            </template>
                        </el-table-column>  
                    </el-table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane> 
                <span slot="label"><i class="iconfont icon-xingji"></i>品牌</span>
                <pageBrand></pageBrand>
            </el-tab-pane>
            <el-tab-pane> 
                <span slot="label"><i class="iconfont icon-gongyingshangguanli" style="padding-left:20px;color:red;font-size:18px;"></i>制造商</span>
                <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black;">
                    <span style="font-size:14px"> 制造商下拉菜单( 提示：保存后将会改变前端界面 )
                        <el-link :underline="false" @click="addSup"><i class="iconfont icon-xinzeng" style="font-size:15px;color:white;padding-left:20px;">【新增记录】</i></el-link>
                        <el-link :underline="false" @click="saveSup"><i class="iconfont icon-baocun" style="font-size:15px;color:white">【保存记录】</i></el-link>
                        <el-link :underline="false" @click="redoSup"><i class="iconfont icon-chexiao" style="font-size:15px;color:#F2F6FC">【取消保存】</i></el-link>
                    </span> 
                </div>
                <div style="display:flex;padding-left:50px;">
                <el-dropdown >
                    <div  class="el-dropitem">
                        <span >制造商</span>
                    </div>
                        <el-dropdown-menu slot="dropdown"  >
                            <template v-for="(item, index) in supData">
                                <el-dropdown-item   :key="index" :divided="index>0">  
                                    <span v-if="item.BANDPATH" >
                                        <el-image   :src="item.BANDPATH" fit="fill" style="width:50px;height:20px;margin-right:5px; " ></el-image>
                                    </span>
                                    <span v-else >
                                        <i class="iconfont icon-wodegongyingshang" style="font-size:24px;color:#409eff"></i>
                                    </span>
                                    <el-link :underline="false"   @click="menuClick(item.SUPNO,item.SUPNM )">{{item.SUPSNM}}</el-link>
                                </el-dropdown-item>
                            </template>
                        </el-dropdown-menu>                         
                </el-dropdown>
                <div style="line-height:50px;font-size:14px;font-weight:600;margin-left:20px;color:#909399">提示：制造商下拉菜单左边的图标来源于 供应商的 品牌LOGO, 如没上传就显示系统默认图标</div>
                </div>
                <div>
                <el-table  :data="supData" 
                    border                            
                    size="small"
                    :height="550" 
                    :row-key="getRowKey"
                    ref="refsup"
                    highlight-current-row > 
                    <span v-for="(item,index ) in ftyCol" :key="index" > 
                        <el-table-column   v-if="item.THIDE=='N'&&item.FLDNO!=='IMGPATH'"
                            :prop="item.FLDNO"
                   
                            :label="item.FLDNM"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="160">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="supEdit(scope.row,scope.$index )">编辑</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="supDel(scope.row,scope.$index )">删除</el-button>  
                        </template>
                    </el-table-column>  
                </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane> 
                <span slot="label"><i class="iconfont icon-ziyuanku"></i>目录画册（PDF或视频）</span>
                <page-solute></page-solute>
            </el-tab-pane>
            <el-tab-pane> 
                <span slot="label"><i class="iconfont icon-xingji"></i>页脚菜单（中英文界面）</span>
                <div>
                <el-table  :data="footData" 
                    border                            
                    size="small"
                    :height="400" 
                    :row-key="getIdKey"
                    @row-click="footClick"
                    ref="refsup"
                    highlight-current-row > 
                    <span v-for="(item,index ) in footCol" :key="index" > 
                        <el-table-column   v-if="item.THIDE=='N' "
                            :prop="item.FLDNO"
          
                            :label="item.FLDNM"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="footEdit(scope.row,scope.$index )">编辑</el-button>
                        </template>
                    </el-table-column>   
                </el-table>
                </div>
                <el-table  :data="fdtlData" 
                    border                            
                    size="small"
                    :height="subHeight" 
                    :row-key="getRowKey"
              
                    highlight-current-row > 
                    <span v-for="(item,index ) in footCol" :key="index" > 
                        <el-table-column   v-if="item.THIDE=='N' "
                            :prop="item.FLDNO"
                            :label="item.FLDNM"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="fdtlEdit(scope.row,scope.$index )">编辑</el-button>
                            <!-- <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="footEdit(scope.row,scope.$index )">删除</el-button> -->
                        </template>
                    </el-table-column>   
                </el-table>
            </el-tab-pane>
            <el-tab-pane style="height:600px;background:white"> 
                <span slot="label"><i class="iconfont icon-shouye2"></i>首页注册页 (max 400kb)</span>
                <div style="display:flex">
                    <div style=" margin-top:20px;margin-left:200px">                   
                        <el-image :preview-src-list="[regObj['IMGPATH']]" :src="regObj['IMGPATH']" style="width:120px;height:140px;margin-right:20px;" ></el-image>                                                                         
                    </div>
                    <div  style="margin-left:30px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="400"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:180px;height:100%;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('reg')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                    </div> 
                </div>
                <div style="line-height:35px;height:40px;padding-left:100px;background:#f7f8f9;margin-top:20px">
                    <el-checkbox true-label="Y" false-label="N" v-model="regObj['ISOK']">首页显示注册页面</el-checkbox>
                    <el-button size="small" type="primary" style="margin-left:40px" icon="iconfont icon-baocun" @click="regSave">保存</el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!--  轮播编辑 -->
        <el-dialog  :show-close="false" :visible.sync="banWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{banFlag==='create'?'新增记录 (max 400kb; png,jpg,jpeg,gif,webp)':'编辑记录 (max 400kb; png,jpg,jpeg,gif,webp)'}}</span>
            </div> 
            <Form :model="banRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in banCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="banRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="banRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex;">
                    <div style=" margin-top:20px; ">                   
                        <el-row>
                            <el-image  :src="banPath" style="width:100%;height:120px;margin-right:20px;padding-left:120px;" ></el-image>
                        </el-row> 
                        <el-row > 
                            <el-link   :underline="false" @click="imgPreview('ban')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-left:20px; padding-left:120px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="400"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:180px;height:100%;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('ban')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
 
                    </div> 
                </div>
            </Form>
            <div style="border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center;">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="banWin=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="banAppend"  style="margin-right:10px;">确定</el-button>
            </div>
        </el-dialog>
        <!--  热销产品编辑 -->
        <el-dialog  :show-close="false" :visible.sync="hotWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{hotFlag==='create'?'新增记录（绑定）':'编辑记录（绑定）'}}</span>
            </div> 
            <Form :model="hotRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in hotCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                     
                        <el-input type="text" v-if="item.key==='IDNO'" disabled v-model="hotRow[item.key]">
                            <div slot="append">
                                <el-button @click="prdChoose" icon="iconfont icon-icon-choose" style="width:90px;color:blue;font-weight:bolder;font-size:22px;"></el-button>
                            </div>
                        </el-input>
                        <el-input-number   v-else-if="item.EDCSS==='number' "   v-model="hotRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="hotRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center; ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="hotWin=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="hotAppend"  style="margin-right:10px;">确定</el-button>
            </div>
        </el-dialog>
        <!-- 授权长条形广告 -->
        <el-dialog :visible.sync="authPic" width="1250px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:15px;">更新横幅图（尺寸：1230x120；最大180kb; png,jpg,jpeg,gif,webp)</span>
            </div>
            <el-image :src="adPath" fit="fill" style="width:1230px;height:120px; "></el-image>
            <el-link :underline="false"  >
                <Upload
                    ref="upload"
                    name="upfile"
                    :data="uploadData"
                    :show-upload-list="false"
                    :before-upload="beforeImgFile"
                    :on-success="successImgFile"
                    :on-progress="handleProgress"
                    :accept="Accept"                      
                    :format="['jpg','jpeg','png','gif','webp' ]"
                    :max-size="180"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :action="uploadUrl"
                    style="display: inline-block;width:90px; ">                           
                    <div style="padding: 20px 20px;width:180px;height:100%;">
                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('horauth')"></el-image> 
                    </div>                                                       
                </Upload> 
            </el-link>
            <div style="border-top:0px solid #ccc;padding-top:4px;padding-left:20px;display:flex;justify-content: center; ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="authPic=false" style="margin-left:50px;margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="adSave"  style="margin-right:10px;">保存图片</el-button>
            </div>
        </el-dialog>
        
        <!-- 预览图片 -->
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:15px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="prevPath"  style="width:99%;height:400px;margin-top:5px;"></el-image> 
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <!-- 选择产品 -->
        <el-dialog class="pub_dialog" :visible.sync="selPrdWin" width="1300px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择产品</span>
            </div> 
            <prdInfo  @child-mat="prdSel"></prdInfo>
        </el-dialog>
        <!--  3原厂对接-->
        <el-dialog  :show-close="false" :visible.sync="ftyWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{banFlag==='create'?'新增记录（最大30Kb; png,jpg,jpeg,gif,webp）':'编辑记录（最大30Kb; png,jpg,jpeg,gif,webp）'}}</span>
            </div> 
            <Form :model="ftyRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in ftyCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="ftyRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select   v-if="item.FLDNO==='SUPNO' "  filterable  v-model="ftyRow[item.FLDNO]" style="width:100%">
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="ftyRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px; ">                   
                        <el-row>
                            <el-image  :src="ftyPath" style="width:200px;height:100%;margin-right:20px;padding-left:120px;" ></el-image>
                        </el-row> 
                        <el-row >
                            <el-link   :underline="false" @click="imgPreview('fty')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-top:2px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="30"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:120px;height:100%;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('fty')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                     </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;display:flex;justify-content:center;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="ftyWin=false" style="margin-right:70px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="ftyAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
        <!--  4授权厂商-->
        <el-dialog  :show-close="false" :visible.sync="authWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{authFlag==='create'?'新增记录（最大20Kb;jpg,png,jpeg,gif,webp)':'编辑记录（最大20Kb;jpg,png,jpeg,gif,webp)'}}</span>
            </div> 
            <Form :model="authRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in ftyCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="authRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select   v-if="item.FLDNO==='SUPNO' "  filterable  v-model="authRow[item.FLDNO]" style="width:100%">
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="authRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px; ">                   
                        <el-row>
                            <el-image  :src="authPath" style="width:180px;height:100%;margin-right:20px;padding-left:120px;" ></el-image>
                        </el-row> 
                        <el-row >
                            <el-link   :underline="false" @click="imgPreview('auth')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-top:2px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="20"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 20px 20px;width:180px;height:100%;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="upType('auth')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                     </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;display:flex;justify-content:center;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="authWin=false" style="margin-right:70px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="authAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
        <!-- 制造商明细 -->
        <el-dialog  :show-close="false" :visible.sync="supWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{authFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="supRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in ftyCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'&&item.FLDNO!=='IMGPATH'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="supRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select  v-else-if="item.FLDNO==='SUPNO'" @change="supSel" v-model="supRow[item.FLDNO]" style="width:100%" filterable clearable>
                            <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM"   ></el-option>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="supRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;  ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="supWin=false" style="margin-right:50px;line-height:20px">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="supAppend"  style="margin-right:10px;line-height:20px">确定</el-button>
            </div>
        </el-dialog>
        <!-- 页脚明细 -->
        <el-dialog  :show-close="false" :visible.sync="footWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;"> 编辑记录 </span>
            </div> 
            <Form :model="footRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in footCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class="item.FLDNO=='ENAME'?'item-width2': 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N' ">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="footRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="textarea" v-else-if="item.FLDNO=='ENAME'" v-model="footRow[item.FLDNO]" :rows="3"></el-input>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="footRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:0px solid #ccc;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="footWin=false" style="margin-right:50px;line-height:20px">取消</el-button>
                    <el-button type="parimary" size="small" icon="iconfont icon-baocun" @click="footSave"  style="margin-right:10px;line-height:20px">确定</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog  :show-close="false" :visible.sync="footDlg" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px','margin-bottom':'10px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;"> 编辑记录 </span>
            </div> 
            <Form :model="footRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in footCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class="item.FLDNO=='ENAME'?'item-width2': 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N' ">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="footRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="textarea" v-else-if="item.FLDNO=='ENAME'" v-model="footRow[item.FLDNO]" :rows="3"></el-input>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="footRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:40px;border-top:0px solid #ccc;  ">
                <span style="float:right;line-height:30px">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="footDlg=false" style="margin-right:50px;line-height:20px">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="fdtlSave"  style="margin-right:10px;line-height:20px">确定</el-button>
                </span>
            </div>
        </el-dialog>
    </div>  
</template>
<script>
import prdInfo from '../datamgt/product_tmpl.vue' 
import pageBrand from './page_brand.vue'
import pageSolute from './page_solution.vue'
import { getBaseData,getTabColOrData } from '../../api/user'
import {  paramData } from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
//数组元素重新按某个属性排序
var compareObj=function(prop){
    return function(obj1,obj2){
        var val1 =obj1[prop]?Number(obj1[prop]):0
        var val2 =obj2[prop]?Number(obj2[prop]):0
        if (val1<val2){
            return -1
        }else if(val1>val2){
            return 1
        }else{
            return 0
        }
    }
}
//局部注册
export default {
    name:"page_management",
    data () {
        return {
            footDlg:false,
            footRow:{},
            footWin:false,
            fdtlData:[],
            supData:[],
            supRow:{},
            supFlag:'create',
            delSupArr:[],
            supIndex:'',
            supBef:{},
            supArr:[],
            supWin:false,
            authPic:false,
            selPrdWin:false,
            Accept: "png,jpg,jpeg,gif,webp", //上传文件格式限制
            uploadData:{}, //上传数据
            videoFlag:false,
            videoUploadPercent:0,
            upFlag:'',
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            banPath:'',
            ftyPath:'',
            authPath:'',
            showViewer:false,
            prevPath:'',
            banCol:[],
            banData:[],
            banWin:false,
            banFlag:'create',
            banIndex:'',
            banRow:{},
            banBef:{},
            delBanArr:[],
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            hotData:[],
            hotCol:[],
            hotIndex:'',
            hotFlag:'create',
            hotBef:{},
            hotRow:{},
            hotWin:false,
            delHotArr:[],
            ftyData:[],
            ftyCol:[],
            ftyWin:false,
            ftyRow:{},
            ftyFlag:'create',
            ftyIndex:'',
            ftyBef:{},
            delFtyArr:[],
            authData:[],
            authCol:[],
            authWin:false,
            authRow:{},
            authFlag:'create',
            authIndex:'',
            authBef:{},
            delAuthArr:[],
            advertPath:'',
            adPath:'',
            footData:[],
            footCol:[],
            regObj:{},
        }
    },
    mounted () {   
        this.getBanList()
        this.getHotList()
        this.getFtyList()
        this.getAuthList()
        this.getAdvert()
        this.getSupList()
        this.getFootList()
        //供应商数据
        paramData('','' ,'V_COMBO_SUP').then((res)=>{               
            this.supArr =res.data.result
        })
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_foot','idseq','57485','','','','','' ).then((res) => { 
            this.regObj =res.data[0]
        }) 
    },
    computed: {
        subHeight: function() {
            return (window.innerHeight - 440) + 'px';
        }, 
        bodyHeight: function() {
            return  '99vh';
        }, 
    },
    components: {
        prdInfo,  //产品选择
        pageBrand,//品牌页面维护
        pageSolute,
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
  
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
 
    },
    methods: { 
        footClick(row){
            this.getFootDtl(row.IDSEQ)
        },
        footSave(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                data: {p_json:JSON.stringify( this.footRow  ), p_table:'bs_front',frmid:''},
            }).then(res=>{ 
                this.getFootList()
                this.footWin=false
            })
        },
        fdtlSave(){
            let vm =this
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.v_username+'&password='+vm.v_password,
                data: {p_json:JSON.stringify(vm.footRow), p_table:'bs_frontdtl',p_idseq:''},
            }).then(res=>{          
                    vm.footDlg =false  
                    this.getFootDtl(vm.footRow['IDSEQ'])
     
            }) 
        },
        supSel(val){
            let obj =this.supArr.find(item=>{
                return item.IDNO===val
            })
            this.supRow['SUPNM'] =obj['SUPNM']
            this.supRow['SUPSNM'] =obj['SUPSNM']   
        },
        menuClick(supno,supnm){
            let routeUrl = this.$router.resolve({
                path: '/hotBrand',
                query: {p_code: supno,p_name:supnm},
            });
            window.open(routeUrl.href, '_blank');
        },
        //注册页保存
        regSave(){
            this.$confirm('你确定要保存吗' ,'提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //console.log(JSON.stringify(this.regObj))
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password ,
                    data: {p_key:'idseq',p_value:'57485', p_table:'BS_foot',p_cond:' set imgpath=\''+this.regObj['IMGPATH']+'\',isok=\''+this.regObj['ISOK'] +'\''},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        this.$message({ message: '已成功保存！', type: 'success'})
                    }     
                })
            }).catch(()=>{})
        },
        adSave(){
            this.$confirm('你确定要更新此图片到前端吗' ,'提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/updateRec' ,
                    data: {p_key:'idseq',p_value:'38618',p_key2:'lstseq',p_value2:'49',p_table:'BS_img',p_cond:' set imgpath=\''+this.adPath+'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        this.authPic=false
                        this.advertPath=this.adPath
                    }     
                })
            }).catch(()=>{})
        },
        advertEdit(){
            this.authPic=true
            this.adPath=this.advertPath
        },
        upType(val){
            this.upFlag=val
        },
        //选择产品
        prdChoose(){
            this.selPrdWin=true
        },
        prdSel(obj){
            this.hotRow['IDNO']=obj['IDNO']
            this.hotRow['ENAME']=obj['ENAME']
            this.hotRow['CNAME']=obj['CNAME']
            this.hotRow['MATDESC']=obj['MATDESC']
            this.hotRow['TAXPRC']=obj['TAXPRC']
            this.hotRow['IMGPATH']=obj['IMGPATH']
            this.selPrdWin=false
        },
        //轮播保存
        saveBan(){
            this.$confirm('你确定要保存轮播图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //console.log(JSON.stringify(this.banData))
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.banData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delBanArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delBanArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delBanArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //热销产品
        saveHot(){
            this.$confirm('你确定要保存热销产品吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.hotData), p_table:'bs_img',p_idseq:'38618' },
                    //这里没有解决V_MAT_HOT里IMGPATH未同步的问题点。
                  //改了没用data: {p_json:JSON.stringify(this.hotData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delHotArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delHotArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delHotArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //原厂对接
        saveFty(){
            this.$confirm('你确定要保存原厂对接吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.ftyData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delFtyArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delFtyArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delFtyArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //授权厂商
        saveAuth(){
            this.$confirm('你确定要保存授权厂商吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.authData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delAuthArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delAuthArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delAuthArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        //前端制造商
        saveSup(){
            this.$confirm('你确定要保存制造商吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //批量保存
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.supData), p_table:'bs_img',p_idseq:'38618' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })
                //批量删除
                for (let k=0;k<this.delSupArr.length;k++) {
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:this.delSupArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delSupArr[k].LSTSEQ, p_table:'bs_img'},
                    })
                } 
            }).catch(()=>{})
        },
        addBan(){
            this.banRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'BANNER'}
            this.banWin=true
            this.banFlag='create'
            this.banPath=''
        },
        addHot(){
            this.hotRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'HOT'}
            this.hotWin=true
            this.hotFlag='create'
        },
        addFty(){
            this.ftyRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'FTY'}
            this.ftyWin=true
            this.ftyFlag='create'
            this.ftyPath=''
        },
        addAuth(){
            this.authRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'AUTH'}
            this.authWin=true
            this.authFlag='create'
            this.authPath=''
        },
        addSup(){
            this.supRow={IDSEQ:38618,LSTSEQ:'',IMGTY:'SUP'}
            this.supWin=true
            this.supFlag='create'
        },
        redoBan(){
            this.$confirm('你确定要取消保存轮播图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getBanList()
            }).catch(()=>{})
        },
        redoHot(){
            this.$confirm('你确定要取消保存热销品牌资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getHotList()
            }).catch(()=>{})
        },
        redoFty(){
            this.$confirm('你确定要取消保存原厂对接资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getFtyList()
            }).catch(()=>{})
        },
        redoAuth(){
            this.$confirm('你确定要取消保存授权厂商资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getAuthList()
            }).catch(()=>{})
        },
        redoSup(){
            this.$confirm('你确定要取消保存制造商资料吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getSupList()
            }).catch(()=>{})
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + '文件太大，不能超出' + this.maxSize + 'Kb!'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确, 请选择 jpg, or png, or gif, or webp' 
            this.promptWin=true
        }, 
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            if (this.upFlag=='ban'){
                this.banPath=res.result
                this.banRow['IMGPATH']=res.result
            }else if (this.upFlag=='fty'){
                this.ftyPath=res.result
                this.ftyRow['IMGPATH']=res.result
            }else if (this.upFlag=='auth'){
                this.authPath=res.result
                this.authRow['IMGPATH']=res.result
            }else if (this.upFlag=='horauth'){
                this.adPath=res.result
            }else if (this.upFlag=='reg'){
                this.regObj['IMGPATH']=res.result
            }
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            this.uploadData = {
                p_user:this.v_username,
                p_idseq:  uuid(),
                p_table:'',
                p_filety:'',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        imgPreview(val){
            if (val=='fty'){
                this.prevPath=this.ftyRow['IMGPATH']
            }else if (val=='ban'){
                this.prevPath=this.banRow['IMGPATH']
            }else if (val=='auth'){
                this.prevPath=this.authRow['IMGPATH']
            }
            this.showViewer=true
        },
        banAppend(){
            if (this.banFlag==='create'){
                this.banData.push(this.banRow)
            }else{
                this.banData.splice(this.banIndex,1,Object.assign(this.banBef,this.banRow) )
            }  
            this.banData.sort(compareObj('SORTBY'))
            this.banWin=false
        },
        hotAppend(){
            if (this.hotFlag==='create'){
                this.hotData.push(this.hotRow)
            }else{
                this.hotData.splice(this.hotIndex,1,Object.assign(this.hotBef,this.hotRow) )
            }
            this.hotData.sort(compareObj('SORTBY'))
            this.hotWin=false
        },
        ftyAppend(){
            if (this.ftyFlag==='create'){
                this.ftyData.push(this.ftyRow)
            }else{
                this.ftyData.splice(this.ftyIndex,1,Object.assign(this.ftyBef,this.ftyRow) )
            }  
            this.ftyData.sort(compareObj('SORTBY'))
            this.ftyWin=false
        },
        authAppend(){
            if (this.authFlag==='create'){
                this.authData.push(this.authRow)
            }else{
                this.authData.splice(this.authIndex,1,Object.assign(this.authBef,this.authRow) )
            }  
            this.authData.sort(compareObj('SORTBY'))
            this.authWin=false
        },
        supAppend(){
            if (this.supFlag==='create'){
                this.supData.push(this.supRow)
            }else{
                this.supData.splice(this.supIndex,1,Object.assign(this.supBef,this.supRow) )
            }  
            this.supData.sort(compareObj('SORTBY'))
            this.supWin=false
        },
        getRowKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        getIdKey(row){
            return row.IDSEQ
        },
        getTabCol(){
            //轮播图
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41190','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.banCol = res.data
            })
            //热销产品
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41211','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.hotCol = res.data
            })
            //原厂对接
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41212','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.ftyCol = res.data
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41212','fty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                this.footCol = res.data
            })
        },
        banDel(row,index){
            this.delBanArr.push(row)
            this.banData.splice(index,1 )
        },
        hotDel(row,index){
            this.delHotArr.push(row)
            this.hotData.splice(index,1 )
        },
        ftyDel(row,index){
            this.delFtyArr.push(row)
            this.ftyData.splice(index,1 )
        },
        authDel(row,index){
            this.delAuthArr.push(row)
            this.authData.splice(index,1 )
        },
        supDel(row,index){
            this.delSupArr.push(row)
            this.supData.splice(index,1 )
        },
        banEdit(row,index){
            this.banFlag='edit'
            this.banRow=JSON.parse(JSON.stringify(row))
            this.banWin=true
            this.banIndex=index
            this.banBef=row
            this.banPath=row['IMGPATH']
            this.delBanArr=[]
        },
        hotEdit(row,index){
            this.hotFlag='edit'
            this.hotRow=JSON.parse(JSON.stringify(row))
            this.hotWin=true
            this.hotIndex=index
            this.hotBef=row
            this.delHotArr=[]
        },
        ftyEdit(row,index){
            this.ftyFlag='edit'
            this.ftyRow=JSON.parse(JSON.stringify(row))
            this.ftyWin=true
            this.ftyIndex=index
            this.ftyBef=row
            this.delFtyArr=[]
            this.ftyPath=row['IMGPATH']
        },
        authEdit(row,index){
            this.authFlag='edit'
            this.authRow=JSON.parse(JSON.stringify(row))
            this.authWin=true
            this.authIndex=index
            this.authBef=row
            this.delAuthArr=[]
            this.authPath=row['IMGPATH']
        },
        supEdit(row,index){
            this.supFlag='edit'
            this.supRow=JSON.parse(JSON.stringify(row))
            this.supWin=true
            this.supIndex=index
            this.supBef=row
            this.delSupArr=[]
        },
        footEdit(row,index){
            this.footRow=JSON.parse(JSON.stringify(row))
            this.footWin=true
 
        },
        fdtlEdit(row,index){
            this.footRow=JSON.parse(JSON.stringify(row))
            this.footDlg=true
 
        },
        //轮播明细 
        getBanList(){
            this.banData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','BANNER','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.banData =res.data 
                this.$nextTick(()=>{
                    this.$refs.refdtl.doLayout()
                })
            })
        },
        //热销产品 
        getHotList(){
            this.banData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_MAT_HOT','','','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.hotData =res.data 
                this.$nextTick(()=>{
                    this.$refs.refhot.doLayout();
                })
            })
 
        },
        //原厂对接
        getFtyList(){
            this.ftyData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','FTY','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.ftyData =res.data 
                this.$nextTick(()=>{
                    this.$refs.reffty.doLayout();
                })
            })
 
        },
        //授权厂商
        getAuthList(){
            this.authData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','AUTH','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.authData =res.data 
                this.$nextTick(()=>{
                    this.$refs.refauth.doLayout();
                })
            })
 
        },
        //制造商
        getSupList(){
            this.supData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','SUP','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.supData =res.data 
                this.$nextTick(() => {
                    this.$refs['refsup'].doLayout();
                } );
            })   
        },
        //页脚资料
        getFootList(){
            this.footData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_front','','','','','','',' order by sortby ' ).then((res) => { 
                this.footData =res.data 
                this.fdtlData=[]
                if (res.data.lenght>0){
                    this.getFootDtl(res.data[0]['IDSEQ'])
                }
            })   
            
        },
        getFootDtl(v_idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_FRONTitm','idseq',v_idseq,'','','','',' order by sortby ' ).then((res) => { 
                this.fdtlData =res.data 
 
            })   
        },
        getAdvert(){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_IMG','imgty','ADVERT','','','','',' order by sortby,lstseq' ).then((res) => { 
                this.advertPath =res.data[0].IMGPATH 
            })
        },
    }
}
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .item-width2  {
        width: 97%;
        color:#409EFF;
    }
    .el-dropitem {  
        cursor: pointer;
        font-size:15px;
        color:rgb(143, 114, 114) !important;
        height:50px;
        line-height:50px;
        text-align:center;
    } 
    .el-aside {
        height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
    }
    .card-div {
        padding: 14px;
        background:#EBEEF5; 
        text-align:center;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#e6a23c 2px solid;
    }
    .img-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        line-height:20px;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
    .group-css{
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      width:1230px;
    }
    .wraper{
        width:1250px;
        margin:1px auto;
    }
 
</style>
 